<script lang="ts" setup>
import { E_RANK_TYPE } from '~/enums'

defineProps<{
  pageType: E_RANK_TYPE
  isDetail?: boolean
}>()
const { t } = useI18n()
const showPopup = defineModel({ default: false })
const filterRef = useTemplateRef('filter-ref')

function onConfirm() {
  showPopup.value = false
  filterRef.value?.onConfirm()
}
</script>

<template>
  <BaseAZDialog
    v-model:show="showPopup"
    :title="t('key.experience.add.filters')"
  >
    <BusinessBFilter v-if="pageType === E_RANK_TYPE.ATTRACTIONS && showPopup" ref="filter-ref" :is-detail="isDetail" :columns="2" />
    <BusinessBExerienceFilter v-if="pageType === E_RANK_TYPE.EXPERIENCE" ref="filter-ref" />
    <template #footer>
      <div px="6" h="24" flex="~ items-center justify-end" class="van-hairline--top">
        <el-button size="large" @click="showPopup = false">
          {{ t('key.common.button.cancel') }}
        </el-button>
        <el-button size="large" type="primary" @click="onConfirm">
          {{ t('key.common.button.confirm') }}
        </el-button>
      </div>
    </template>
  </BaseAZDialog>
</template>

<style lang="scss" scoped>
.az-search__popup {
  height: 100%;
  :deep(.van-popup__close-icon) {
    top: 11px;
  }
}
</style>
