<script lang="ts" setup>
import { E_RANK_TYPE } from '~/enums'

defineProps<{
  pageType: E_RANK_TYPE
  isDetail?: boolean
}>()
const showPopup = defineModel({ default: false })
const { t } = useI18n()
const filterRef = useTemplateRef('filter-ref')

function onConfirm() {
  showPopup.value = false
  filterRef.value?.onConfirm()
}
</script>

<template>
  <BaseAZPopup
    v-model:show="showPopup"
    :title="t('key.experience.add.filters')"
  >
    <div class="h-full" flex="～ col">
      <div class="flex-1 overflow-y-auto">
        <div mb-9>
          <BusinessBFilter v-if="pageType === E_RANK_TYPE.ATTRACTIONS" ref="filter-ref" :is-detail="isDetail" />
          <BusinessBExerienceFilter v-if="pageType === E_RANK_TYPE.EXPERIENCE" ref="filter-ref" />
        </div>
      </div>
      <div mx="-4" p="4" shadow="[0_-1px_0_0_#00000014]">
        <div class="azgo-haptics-feedback" bg="#121212" h="12" rounded="8" flex="~ items-center justify-center" @click.stop="onConfirm">
          <span text="base white">
            {{ t('key.business.select.ticket.apply') }}
          </span>
        </div>
      </div>
    </div>
  </BaseAZPopup>
</template>

<style lang="scss" scoped>
.az-search__popup {
  height: 100%;
  :deep(.van-popup__close-icon) {
    top: 11px;
  }
}
</style>
